import { addCookie, AnonymousObject, AppContext, get, HTTPError, Idp, IdpSettings, parseJwt } from 'onekijs';
import { roleHasPermission } from './modules/core/libs/rbac';

export default {
  server: {
    baseUrl: '/dev-portal-v1',
    docsUrl: '/asciidoc',
    testReportUrl: '/test-reports'
  },
  idp: {
    cognito: {
      type: 'oidc_browser',
      clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      authorizeEndpoint: (params: AnonymousObject, idp: IdpSettings) => {
        const search = Object.keys(params).reduce((accumulator, key) => {
          accumulator += accumulator.length > 1 ? '&' : '';
          return `${accumulator}${key}=${params[key]}`;
        }, '?');
        const signup = localStorage.getItem('onekijs.signup');
        localStorage.removeItem('onekijs.signup');
        return `${signup === 'true' ? idp.signupUrl : idp.authorizeUrl}${search}`;
      },
      tokenEndpoint: process.env.REACT_APP_COGNITO_TOKEN_ENDPOINT,
      userinfoEndpoint: (idp: Idp, context: AppContext): AnonymousObject => {
        const idToken = get(context.store.getState(), 'auth.token.id_token');
        const expiresIn = get(context.store.getState(), 'auth.token.expires_in');
        const accessToken = get(context.store.getState(), 'auth.token.access_token');
        if (!idToken) {
          throw new HTTPError(401);
        }
        addCookie('devportal.access_token', accessToken, false, expiresIn, '/');
        return parseJwt(idToken) as AnonymousObject;
      },
      externalLogoutEndpoint: process.env.REACT_APP_COGNITO_LOGOUT_ENDPOINT,
      jwksEndpoint: process.env.REACT_APP_COGNITO_JWKS_ENDPOINT,
      postLogoutRedirectKey: 'logout_uri', // the key used by the external provider to know which is the callback URL after a successfull logout (defaults to post_logout_redirect_uri which is the oidc standard)
      scope: 'openid profile email service/common',
      authorizeUrl: process.env.REACT_APP_COGNITO_AUTHORIZE_ENDPOINT,
      signupUrl: process.env.REACT_APP_COGNITO_SIGNUP_ENDPOINT,
      hasPermission: (securityContext: AnonymousObject, permission: string, rw = 'r') => {
        if (!securityContext) return false;
        if (!securityContext['cognito:groups']) return false;
        return roleHasPermission(securityContext['cognito:groups'], permission, rw);
      },
    },
  },
  projectGenerator: {
    cognito: {
      local: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_HvxAH2tVO',
      dev: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_HvxAH2tVO',
      tst: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_kvEA87zVX',
      acc: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_kvEA87zVX',
      prd: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_NZlmBXqva',
    },
    awsAccount: {
      local: '819310250698',
      dev: '819310250698',
      tst: '973804288029',
      acc: '973804288029',
      prd: '993879870829',
    },
    awsSamBucket: {
      local: 'sam-dev-flora-insure',
      dev: 'sam-dev-flora-insure',
      tst: 'sam-tst-flora-insure',
      acc: 'sam-acc-flora-insure',
      prd: 'sam-flora-insure',
    },
  },
};
